<template>
  <div id="container" :style="{ width: (screenWeight <= 1024 ? 30 : 50) + 'rem', height: (screenWeight <= 1024 ? 14.37 : 30) + 'rem' }"></div>
</template>

<script>
import AMap from 'AMap' // 引入高德地图

export default {
  data() {
    return {
      mapWidth: 50,
      mapHeight: 30,
      screenWeight: 0
    }
  },
  created() {
    this.screenWeight = document.documentElement.clientWidth
  },
  mounted() {
    this.test()
  },
  methods: {
    test() {
      const map = new AMap.Map('container', {
        resizeEnable: true,
        zoom: 16,
        center: [120.377515, 36.07654],
        radius: 1000,
        scrollWheel: false,
        viewMode: '3D'
      })
      let offset = new AMap.Pixel(85, -20)
      if (this.screenWeight >= 1024) {
        map.plugin(['AMap.ToolBar'], () => {
          // 加载工具条
          var tool = new AMap.ToolBar()
          map.addControl(tool)
        })
        offset = new AMap.Pixel(0, -20)
      }
      // 创建纯文本标记
      const text = new AMap.Text({
        text: '海信创业中心',
        anchor: 'center', // 设置文本标记锚点
        draggable: false,
        cursor: 'pointer',
        offset,
        style: {
          padding: '.75rem 1.25rem',
          'margin-bottom': '1rem',
          'border-radius': '.25rem',
          'background-color': 'white',
          width: '15rem',
          'border-width': 0,
          'box-shadow': '0 .2rem .6rem 0 rgba(114, 124, 245, .5)',
          'text-align': 'center',
          'font-size': '1rem'
        },
        position: [120.377515, 36.07654]
      })
      text.setMap(map)
      // const marker = new AMap.Marker({
      //   icon: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
      //   position: [120.377515, 36.07654],
      //   offset: new AMap.Pixel(-13, -40)
      // })
      // marker.setMap(map)
    }
  }
}
</script>
