<template>
  <Header :curIndex="curIndex" :toPage="toPage"></Header>
  <div class="home">
    <swiper
      ref="mySwiper"
      class="swiper"
      :class="{ 'swiper-no-swiping': screenWeight >= 1024 }"
      :coverflowEffect="swiperOptions.coverflowEffect"
      :centeredSlides="swiperOptions.centeredSlides"
      :slidesPerView="swiperOptions.slidesPerView"
      effect="coverflow"
      :mousewheel="{ releaseOnEdges: true }"
      :direction="'vertical'"
      @slideChange="onSlideChange"
      @swiper="setControlledSwiper"
    >
      <swiper-slide class="index">
        <div class="title" :class="{ animate__animated: curIndex === 1, animate__fadeInUp: curIndex === 1 }">
          <div class="t1"><span>云计算</span>智能分析系统</div>
          <div class="t1">领先的创新能力</div>
        </div>
        <video class="video-bg" :autoplay="screenWeight >= 1024" muted loop>
          <source src="../../assets/video/index-bg.mp4" type="video/mp4" />
        </video>
      </swiper-slide>
      <!-- 产品和解决方案 -->
      <swiper-slide class="products-slide">
        <div class="products">
          <div ref="productsTitle" class="title" :class="{ animate__animated: curIndex === 2, animate__fadeInUp: curIndex === 2 }">
            <div class="es">Products & solutions</div>
            <div class="en">产品和解决方案</div>
          </div>
          <div class="products-content">
            <div class="item" data-item="1" @mouseenter="itemMouseEnter" @mouseleave="itemMouseLeave">
              <div class="c" :class="{ animate__animated: curIndex === 2, animate__fadeInUp: curIndex === 2 }">
                <div class="t">数字孪生</div>
                <img class="img" src="../../assets/products/digital.png" />
                <div class="txt">汇聚运营大数据的企业级数字孪生平台，为数字化政府及企业提供全息可视化蓝图。</div>
              </div>
              <div class="bg"></div>
            </div>
            <div class="item" data-item="2" @mouseenter="itemMouseEnter" @mouseleave="itemMouseLeave">
              <div class="c" :class="{ animate__animated: curIndex === 2, animate__fadeInUp: curIndex === 2 }">
                <div class="t">区块链</div>
                <img class="img" src="../../assets/products/blockchain.png" />
                <div class="txt">分布式数据存储、点对点传输、共识机制、加密算法等计算机技术的新型应用模式。</div>
              </div>
              <div class="bg"></div>
            </div>
            <div class="item" data-item="3" @mouseenter="itemMouseEnter" @mouseleave="itemMouseLeave">
              <div class="c" :class="{ animate__animated: curIndex === 2, animate__fadeInUp: curIndex === 2 }">
                <div class="t">装备全生命周期管理</div>
                <img class="img" src="../../assets/products/equipment.png" />
                <div class="txt">
                  实现域内装备信息共享与功能重组，涵盖从装备系统到单机基线，管理查询变化比对、数据包络分析、状态变化风险分析等功能。
                </div>
              </div>
              <div class="bg"></div>
            </div>
          </div>
          <swiper class="products-swiper" :spaceBetween="50" :pagination="swiperProOption.pagination">
            <!-- <div class="swiper-products-pagination"></div> -->
            <swiper-slide>
              <div class="item" @mouseenter="itemMouseEnter1" @mouseleave="itemMouseLeave1">
                <div class="c" :class="{ animate__animated: curIndex === 2, animate__fadeInUp: curIndex === 2 }">
                  <img class="img" src="../../assets/products/digital.png" />
                  <div class="t">数字孪生</div>
                  <div class="txt">汇聚运营大数据的企业级数字孪生平台，为数字化政府及企业提供全息可视化蓝图。</div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="item" @mouseenter="itemMouseEnter2" @mouseleave="itemMouseLeave2">
                <div class="c" :class="{ animate__animated: curIndex === 2, animate__fadeInUp: curIndex === 2 }">
                  <img class="img" src="../../assets/products/blockchain.png" />
                  <div class="t">区块链</div>
                  <div class="txt">分布式数据存储、点对点传输、共识机制、加密算法等计算机技术的新型应用模式。</div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="item" @mouseenter="itemMouseEnter3" @mouseleave="itemMouseLeave3">
                <div class="c" :class="{ animate__animated: curIndex === 2, animate__fadeInUp: curIndex === 2 }">
                  <img class="img" src="../../assets/products/equipment.png" />
                  <div class="t">装备全生命周期管理</div>
                  <div class="txt">
                    实现域内装备信息共享与功能重组，涵盖从装备系统到单机基线，管理查询变化比对、数据包络分析、状态变化风险分析等功能。
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="products-bg">
          <video class="img-bg" ref="video1" :autoplay="screenWeight >= 1024" muted loop>
            <source src="../../assets/products/digital-bg.mp4" type="video/mp4" />
          </video>
          <video class="img-bg" ref="video2" :autoplay="screenWeight >= 1024" muted loop>
            <source src="../../assets/products/blockchain-bg.mp4" type="video/mp4" />
          </video>
          <video class="img-bg" ref="video3" :autoplay="screenWeight >= 1024" muted loop>
            <source src="../../assets/products/equipment-bg.mp4" type="video/mp4" />
          </video>
        </div>
      </swiper-slide>
      <!-- 公司优势 -->
      <swiper-slide class="company-slide">
        <div class="company">
          <div class="title" :class="{ animate__animated: curIndex === 3, animate__fadeInUp: curIndex === 3 }">
            <div class="es">Company Supremacy</div>
            <div class="en">公司优势</div>
          </div>
          <div class="company-content">
            <div class="tip" :class="{ animate__animated: curIndex === 3, animate__fadeInUp: curIndex === 3 }">
              全面提供覆盖军方、金融、安防、电力、社保、教育、政府等多个行业的解决方案
            </div>
            <div class="c">
              <div class="item">
                <div class="item_ani" :class="{ animate__animated: curIndex === 3, animate__fadeInUp: curIndex === 3 }">
                  <img src="../../assets/company/com1.png" alt="" srcset="" />
                  <div class="t">研发团队强大</div>
                  <div class="txt">专家团队行业领先 核心团队成员均具备资深的研发实施经验</div>
                </div>
              </div>
              <div class="item" :class="{ animate__animated: curIndex === 3, animate__fadeInUp: curIndex === 3 }">
                <div class="item_ani">
                  <img src="../../assets/company/com2.png" alt="" srcset="" />
                  <div class="t">技术实力领先</div>
                  <div class="txt">采用自主知识产权研发，世界领先技术，并 有独到看法及相关研究</div>
                </div>
              </div>
              <div class="item" :class="{ animate__animated: curIndex === 3, animate__fadeInUp: curIndex === 3 }">
                <div class="item_ani">
                  <img src="../../assets/company/com3.png" alt="" srcset="" />
                  <div class="t">产品线丰富</div>
                  <div class="txt">涉及大数据、私有云、数字孪生等高端领域</div>
                </div>
              </div>
              <div class="item" :class="{ animate__animated: curIndex === 3, animate__fadeInUp: curIndex === 3 }">
                <div class="item_ani">
                  <img src="../../assets/company/com4.png" alt="" srcset="" />
                  <div class="t">全场景化覆盖</div>
                  <div class="txt">为军方、金融、安防、电力、社保、教育、政府等各行业领域提供全场景数字解决方案</div>
                </div>
              </div>
              <div class="item" :class="{ animate__animated: curIndex === 3, animate__fadeInUp: curIndex === 3 }">
                <div class="item_ani">
                  <img src="../../assets/company/com5.png" alt="" srcset="" />
                  <div class="t">标准化产品交付</div>
                  <div class="txt">专家团队行业领先核心团队成员均具备资深的研发实施经验</div>
                </div>
              </div>
              <div class="item" :class="{ animate__animated: curIndex === 3, animate__fadeInUp: curIndex === 3 }">
                <div class="item_ani">
                  <img src="../../assets/company/com6.png" alt="" srcset="" />
                  <div class="t">众多客户认可</div>
                  <div class="txt">大量客户案例，拥有众多行业龙头企业及政府军方客户案例</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <!-- 我们的客户 -->
      <swiper-slide class="customers-slide">
        <div class="customers">
          <div class="title" :class="{ animate__animated: curIndex === 4, animate__fadeInUp: curIndex === 4 }">
            <div class="es">Our Customers</div>
            <div class="en">我们的客户</div>
          </div>
          <div class="customers-content">
            <div class="item" :class="{ animate__animated: curIndex === 4, animate__fadeInUp: curIndex === 4 }">
              <div>
                <img src="../../assets/customers/enshi.png" alt="" srcset="" />
                <div class="t">恩施人民政府</div>
              </div>
            </div>
            <div class="item" :class="{ animate__animated: curIndex === 4, animate__fadeInUp: curIndex === 4 }">
              <div>
                <img src="../../assets/customers/qilublank.png" alt="" srcset="" />
                <div class="t">齐鲁银行</div>
              </div>
            </div>
            <div class="item" :class="{ animate__animated: curIndex === 4, animate__fadeInUp: curIndex === 4 }">
              <div>
                <img src="../../assets/customers/heilongjiang.png" alt="" srcset="" />
                <div class="t">黑龙江公安交通管理局</div>
              </div>
            </div>
            <div class="item" :class="{ animate__animated: curIndex === 4, animate__fadeInUp: curIndex === 4 }">
              <div>
                <img src="../../assets/customers/nanjingditie.png" alt="" srcset="" />
                <div class="t">南京地铁</div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <!-- 联系我们 -->
      <swiper-slide class="contact-slide">
        <div class="contact">
          <div class="title" :class="{ animate__animated: curIndex === 5, animate__fadeInUp: curIndex === 5 }">
            <div class="es">Contact Us</div>
            <div class="en">联系我们</div>
          </div>
          <div class="contact-content">
            <div>
              <div class="t" :class="{ animate__animated: curIndex === 5, animate__fadeInUp: curIndex === 5 }">青岛新创融软件责任有限公司</div>
              <div class="c" :class="{ animate__animated: curIndex === 5, animate__fadeInUp: curIndex === 5 }">
                <div class="item">
                  <div class="txt">地址：山东省青岛市市南区山东路17号A座</div>
                  <div class="txt">传真：0532-85816058</div>
                </div>
                <div class="item">
                  <div class="txt">邮编：266000</div>
                  <div class="txt">电话：0532-85816058</div>
                </div>
              </div>
              <div class="code" :class="{ animate__animated: curIndex === 5, animate__fadeInUp: curIndex === 5 }">
                <Amap></Amap>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
  <div class="bottom-nav" :class="{ dn: screenWeight >= 1024 }" :style="{ display: curIndex === 5 ? 'none' : 'block' }">
    <div class="txt" v-if="curIndex === 1">产品和解决方案</div>
    <div class="txt" v-if="curIndex === 2">公司优势</div>
    <div class="txt" v-if="curIndex === 3">我们的客户</div>
    <div class="txt" v-if="curIndex === 4">联系我们</div>
    <i class="iconfont icon-down"></i>
  </div>
  <div class="bottom-nav-pc" :class="{ dn: screenWeight < 1024 }" :style="{ display: curIndex === 1 ? 'none' : 'block' }">
    <div class="bo" :style="{ top: curIndex > 2 ? '-2rem' : -1 * (curIndex - 1) + 'rem' }">
      <div class="page-nav" v-for="(item, index) in pageList" :key="index">
        <div class="t" :style="{ visibility: curIndex === item.id ? 'visible' : 'hidden' }">{{ item.name }}</div>
        <div class="l" :class="{ 'l-active': curIndex === item.id, dn: item.id === 1 }"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Amap from '@/components/Amap.vue'
import { reactive } from 'vue'
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { EffectCoverflow, Mousewheel, Navigation, Pagination } from 'swiper'
SwiperCore.use([Navigation, EffectCoverflow, Mousewheel, Pagination])
export default {
  name: 'Home',
  components: {
    Header,
    Amap,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      pageList: [
        { id: 1, name: '首页' },
        { id: 2, name: '产品和解决方案' },
        { id: 3, name: '公司优势' },
        { id: 4, name: '我们的客户' },
        { id: 5, name: '联系我们' }
      ],
      screenWeight: 1024,
      swiperControll: null,
      curIndex: 1 // 当前页的index
    }
  },
  created() {
    // console.log(document.documentElement.clientWidth, window.screen.width, window.screen.availWidth, window.innerWidth, document.body.scrollWidth)
    // this.screenWeight = document.documentElement.clientWidth
    console.log(window.screen)
    this.screenWeight = document.body.scrollWidth
  },
  setup() {
    // swiper相关配置属性放在swiperOptions这个变量里
    const swiperOptions = reactive({
      slidesPerView: 'auto',
      direction: "'vertical'",
      centeredSlides: true,
      mousewheel: {
        releaseOnEdges: true,
        sensitivity: 100,
        forceToAxis: true
      },
      coverflowEffect: {
        stretch: 50,
        depth: 100,
        modifier: 1
      },
      // 修改swiper自己或子元素时，自动初始化swiper
      observer: true,
      // 修改swiper的父元素时，自动初始化swiper
      observeParents: true
    })
    const swiperProOption = reactive({
      pagination: {
        // el: '.swiper-products-pagination',
        clickable: true
      }
    })
    // 将swiperOptions返回给模板中的swiper组件使用
    return { swiperOptions, swiperProOption }
  },
  mounted() {},
  methods: {
    onSlideChange(swiper) {
      this.curIndex = swiper.activeIndex + 1
    },
    setControlledSwiper(swiper) {
      this.swiperControll = swiper
    },
    toPage(index) {
      const swiper = this.swiperControll
      swiper.slideTo(index, 1000, true)
    },
    // 1
    itemMouseEnter(e) {
      const caseItem = e.target.getAttribute('data-item')
      switch (caseItem) {
        case '1':
          console.log(caseItem)
          this.$refs.video1.style.display = 'block'
          break
        case '2':
          this.$refs.video2.style.display = 'block'
          break
        case '3':
          this.$refs.video3.style.display = 'block'
          break
        default:
          break
      }
    },
    itemMouseLeave(e) {
      const caseItem = e.target.getAttribute('data-item')
      switch (caseItem) {
        case '1':
          this.$refs.video1.style.display = 'none'
          break
        case '2':
          this.$refs.video2.style.display = 'none'
          break
        case '3':
          this.$refs.video3.style.display = 'none'
          break
        default:
          break
      }
    }
  }
}
</script>
