<template>
  <div class="header" :style="{ backGround: curIndex > 1 ? '#071D45' : '' }">
    <div class="top">
      <!-- <img class="logo" alt="XCR logo" src="../assets/logo.png" /> -->
      <img class="logo" alt="XCR logo" src="../assets/logo.svg" />
      <div class="nav">
        <ul>
          <li :class="{ active: curIndex === item.id }" v-for="(item, index) in nav" :key="index" @click="toPage(index)">{{ item.name }}</li>
        </ul>
        <div class="tel">
          <i class="iconfont icon-phone-fill"></i>
          <span>0532-85816058</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: ['curIndex', 'toPage'],
  data() {
    return {
      nav: [
        { id: 1, name: '首页' },
        { id: 2, name: '产品和解决方案' },
        { id: 3, name: '公司优势' },
        { id: 4, name: '我们的客户' },
        { id: 5, name: '联系我们' }
      ]
    }
  },
  // computed: {
  //   curIndex() {
  //     return this.curIndex
  //   }
  // },
  methods: {
    navClick(e) {
      this.curIndex = e
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  // background: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10001;
  display: flex;
  align-items: center;
  justify-content: center;
  .top {
    width: 80%;
    height: 12rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      // margin-left: 16rem;
      width: 15rem;
      height: 5rem;
    }
    .nav {
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-left: 53.5rem;
      font-size: 1.4rem;
      line-height: 2rem;
      // color: #d8d8d8;
      height: 100%;
      .tel {
        margin-left: 6.7rem;
        line-height: 2.08rem;
        .iconfont {
          color: #ffffff;
        }
        span {
          margin-left: 2.75rem;
          font-weight: bold;
          color: #ffffff;
          line-height: 2.08rem;
        }
      }
      ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 12rem;
        line-height: 12rem;
      }
      li {
        display: inline-block;
        // margin-left: 4.67rem;
        margin: 0 2.3rem;
        cursor: pointer;
        height: 100%;
        // color: #969696;
        color: #fff;
        opacity: 0.45;
        &.active {
          opacity: 1;
          // color: #ffffff;
        }
        &:hover {
          // color: #fff;
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (min-width: 375px) and (max-width: 1200px) {
  .header {
    .top {
      height: 5.4rem;
      position: relative;
      z-index: 10003;
      justify-content: center;
      .logo {
        width: 6.3rem;
        height: 2rem;
      }
      .nav {
        display: none;
        height: 100vh;
        width: 100vw;
        background: #061737;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10002;
        transition: width height opacity 0.2s;
        transition-timing-function: ease-in-out;
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 5.4rem;
          height: 5.4rem;
          color: #d8d8d8;
          .iconfont {
            font-size: 3.2rem;
          }
        }
        ul {
          display: flex;
          flex-direction: column;
        }
        li {
          margin: 6.4rem 0 0 2.4rem;
          font-size: 1.6rem;
          font-family: MicrosoftYaHeiUI;
          color: #ffffff;
          line-height: 2rem;
          height: 2rem;
          text-align: left;
        }
      }
    }
    // .mobile-nav {
    //   height: 100vh;
    //   width: 100vw;
    //   background: #061737;
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   z-index: 10002;
    //   transition: width height opacity 0.2s;
    //   transition-timing-function: ease-in-out;
    //   .icon {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     width: 5.4rem;
    //     height: 5.4rem;
    //     color: #d8d8d8;
    //     .iconfont {
    //       font-size: 3.2rem;
    //     }
    //   }
    //   ul {
    //     display: flex;
    //     flex-direction: column;
    //   }
    //   li {
    //     margin: 6.4rem 0 0 2.4rem;
    //     font-size: 1.6rem;
    //     font-family: MicrosoftYaHeiUI;
    //     color: #ffffff;
    //     line-height: 2rem;
    //     height: 2rem;
    //     text-align: left;
    //   }
    // }
  }
}
</style>
