import { createApp } from 'vue'
import '@/scss/index.scss'
import App from './App.vue'
import router from './router'
import 'animate.css'
// Import Swiper styles
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
// import Vue from 'vue'
// Vue.use(animate)

createApp(App).use(router).mount('#app')
